<ng-template
  #row
  let-user>
  <tr>
    <td
      class="apr f1 t-df p-df t-jc p-jc"
      [ngClass]="{
        casemanager: user?.type === InspectionInvolvedUserType.CaseManager,
        contractor: user?.type === InspectionInvolvedUserType.Contractor,
        client: user?.type === InspectionInvolvedUserType.Client,
        'current-user': user?.type === InspectionInvolvedUserType.CurrentUser,
      }">
      <mat-icon
        *ngIf="user?.type === InspectionInvolvedUserType.CurrentUser"
        svgIcon="client"></mat-icon>
      <mat-icon
        *ngIf="user?.type === InspectionInvolvedUserType.CaseManager"
        svgIcon="casemanager"></mat-icon>
      <mat-icon
        *ngIf="user?.type === InspectionInvolvedUserType.Contractor"
        svgIcon="contractor"></mat-icon>
      <mat-icon
        *ngIf="user?.type === InspectionInvolvedUserType.Client"
        svgIcon="client"></mat-icon>
    </td>
    <td class="apr f6 t-df p-df t-jc p-jc">
      <p>{{ user?.name }}</p>
    </td>
    <td class="apr f6">
      <p>{{ user?.mail }}</p>
    </td>
    <td class="apr f2 jc">
      <label>
        <T str="Present"></T>
      </label>
      <mat-slide-toggle
        [checked]="user?.present"
        (change)="user.present = $event.checked; checkIfValid()">
      </mat-slide-toggle>
    </td>
    <td class="apr f2 jc">
      <label>
        <T str="Report"></T>
      </label>
      <mat-slide-toggle
        [checked]="user?.report"
        (change)="user.report = $event.checked"
        [disabled]="!user?.mail || user?.mail === ''">
      </mat-slide-toggle>
    </td>
    <td class="apr f2 jc">
      <label>
        <T str="Signature"></T>
      </label>
      <mat-slide-toggle
        [checked]="user?.signer"
        (change)="user.signer = $event.checked; checkIfValid()"
        [disabled]="
          signatureType === InspectionSignatureType.None ||
          (signatureType === InspectionSignatureType.BankId && (!user?.mail || user?.mail === ''))
        ">
      </mat-slide-toggle>
    </td>
    <td class="apr f3 jc">
      <button
        mat-raised-button
        color="primary"
        (click)="sign(user)"
        [disabled]="
          signatureType === InspectionSignatureType.None || signatureType === InspectionSignatureType.BankId
            ? true
            : user.signer
              ? false
              : true
        ">
        <mat-icon *ngIf="user?.signature">check</mat-icon>
        <T
          str="Signed"
          *ngIf="user?.signature"></T>
        <T
          str="Sign"
          *ngIf="!user?.signature"></T>
      </button>
    </td>
  </tr>
</ng-template>

<ng-container *ngIf="inspection">
  <header>
    <apex-page-info [title]="title">
      <div
        *ngIf="inspectionService.sw"
        class="df f1 jfe">
        <button
          *ngIf="inspectionService.sw"
          mat-icon-button
          [disabled]="true">
          <mat-icon *ngIf="!inspectionService.savingOfflineData">
            {{ inspectionService.offline ? 'wifi_off' : 'wifi' }}
          </mat-icon>
          <mat-spinner
            *ngIf="inspectionService.savingOfflineData"
            color="warn"
            [diameter]="24">
          </mat-spinner>
        </button>
      </div>
    </apex-page-info>
  </header>
  <main>
    <div class="am">
      <div class="aps">
        <T str="Signature type"></T>
      </div>
      <mat-radio-group
        class="df c"
        [attr.aria-label]="'Select an option' | translate"
        [value]="signatureType"
        (change)="setSignatureType($event.value); checkIfValid()">
        <mat-radio-button
          [value]="InspectionSignatureType.None"
          class="aps">
          <T str="None"></T>
        </mat-radio-button>
        <mat-radio-button
          [value]="InspectionSignatureType.Handwritten"
          class="aps">
          <T str="Handwritten"></T>
        </mat-radio-button>
        <mat-radio-button
          [value]="InspectionSignatureType.BankId"
          class="aps">
          <T str="BankID"></T>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="am">
      <table class="apex phone tablet mat-elevation-z2">
        <thead>
          <tr class="header">
            <th class="apr f1"></th>
            <th class="apr f6">
              <T str="Name"></T>
            </th>
            <th class="apr f6">
              <T str="Email"></T>
            </th>
            <th class="apr f2 jc">
              <T str="Present"></T>
            </th>
            <th class="apr f2 jc">
              <T str="Report"></T>
            </th>
            <th class="apr f2 jc">
              <T str="Signature"></T>
            </th>
            <th class="apr f3 jc"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of involvedUsers">
            <ng-container *ngTemplateOutlet="row; context: { $implicit: user }"></ng-container>
          </ng-container>
          <tr
            *ngIf="others && others.length"
            class="others-seperator">
            <td></td>
          </tr>
          <ng-container *ngFor="let user of others">
            <ng-container *ngTemplateOutlet="row; context: { $implicit: user }"></ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
    <button
      mat-fab
      (click)="addOtherUser()"
      [matTooltip]="'Add user' | translate">
      <mat-icon>add</mat-icon>
    </button>

    <footer class="df jsb ac">
      <button
        class="aml"
        color="primary"
        mat-flat-button
        (click)="back()">
        <T str="Back"></T>
      </button>

      <button
        class="amr"
        mat-flat-button
        color="primary"
        (click)="next()"
        [disabled]="invalid">
        <T str="Summary"></T>
      </button>
    </footer>
  </main>
</ng-container>
