import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../apex/src/environments/environment';

const skipUrls = ['http://app.heimdaltest.no:4200/assets/icons.svg', 'https://app.apexapp.io/assets/icons.svg'];

@Injectable()
export class InspectionInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let inspectionId: string;
    const url = new URL(location.href);

    if (skipUrls.includes(req.url)) {
      return next.handle(req);
    }

    if (environment.test) {
      inspectionId = '1';
    } else {
      inspectionId = url?.searchParams?.get('InspectionId');
    }

    if (!inspectionId) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      headers: req.headers.set('InspectionId', inspectionId),
    });

    return next.handle(clonedRequest);
  }
}
