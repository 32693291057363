<h1
  *ngIf="dialogRef"
  mat-dialog-title>
  <T
    str="Signing for {name}"
    [vars]="{ name: user?.name ?? '' }">
  </T>
</h1>
<mat-dialog-content>
  <canvas #canvas></canvas>
</mat-dialog-content>
<mat-dialog-actions class="df jsb">
  <div>
    <button
      mat-button
      (click)="clear()"
      [disabled]="data.viewMode">
      <T str="Clear"></T>
    </button>
  </div>
  <div>
    <button
      mat-button
      (click)="cancel()">
      <T str="Cancel"></T>
    </button>
    <button
      mat-raised-button
      (click)="confirm()"
      color="primary"
      [disabled]="data.viewMode || (!dirty && !delete)">
      <T str="Confirm"></T>
    </button>
  </div>
</mat-dialog-actions>
