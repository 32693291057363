import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InspectionViewPageComponent } from './view/view.component';

import { PageNotFoundComponent } from '../../components/page-not-found/page-not-found.component';
import { InspectionGetResolver } from './inspection.resolve';

const routes: Routes = [
  {
    path: ':id',
    component: InspectionViewPageComponent,
    resolve: {
      inspection: InspectionGetResolver,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class InspectionRoutingModule {}
