import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoPageData } from '../../features/inspection/info/info.types';
import { Autocomplete } from '../../models/autocomplete';
import { ModelName } from '../../utils/types';
import { t } from '../translate/translate.function';
import { ModelChange, ProjectObjectModel } from './project-object-field-selector.types';

@Component({
  selector: 'apex-project-object-field-selector',
  templateUrl: './project-object-field-selector.component.html',
})
export class ProjectObjectFieldSelectorComponent {
  /**
   * the model to set the field / object field on
   */
  @Input() model: ProjectObjectModel | null = null;

  /**
   * The data object to set the field / object field on
   * Will only be used if a model is not provided
   */
  @Input() data: InfoPageData | null = null;

  @Output() modelChange = new EventEmitter<ModelChange>();

  /**
   * Checks whether object has been selected on the model or the data if a model is not provided
   */
  get isObject(): boolean {
    if (this.model) {
      return !!this.model.ObjectId;
    }

    return !!this.data?.Object;
  }

  /**
   * Checks whether project has been selected on the model or the data if a model is not provided
   */
  get isProject(): boolean {
    if (this.model) {
      return !!this.model.ProjectId;
    }

    return !!this.data?.Project;
  }

  /**
   * Gets the hint to show if no object or project is selected on the input model / data
   */
  get hint(): string {
    if (this.isObject || this.isProject) {
      return '';
    }

    return t('Choose a project or an object to select field');
  }

  emitModelChange(autocomplete: Autocomplete): void {
    if (!this.isObject && !this.isProject) {
      return;
    }

    this.modelChange.emit({
      type: this.isObject ? ModelName.Object : ModelName.Project,
      autocomplete,
    });
  }
}
