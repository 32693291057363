<header>
  <apex-page-info [title]="title">
    <div
      *ngIf="inspectionService.sw"
      class="df f1 jfe ac">
      <button
        *ngIf="inspectionService.sw"
        mat-icon-button
        [disabled]="true">
        <mat-icon *ngIf="!inspectionService.savingOfflineData">
          {{ inspectionService.offline ? 'wifi_off' : 'wifi' }}
        </mat-icon>
        <mat-spinner
          *ngIf="inspectionService.savingOfflineData"
          color="warn"
          [diameter]="24">
        </mat-spinner>
      </button>
    </div>
  </apex-page-info>
</header>
<main class="df c f1">
  <ng-container *ngIf="!loading; else loadingView">
    <ng-container *ngIf="checklist">
      <apex-inspection-checklist-info
        class="amh amt"
        [checklistData]="checklist">
      </apex-inspection-checklist-info>
    </ng-container>
    <ng-container *ngIf="checklistGroup">
      <apex-inspection-checklist-info
        class="amh amt"
        [checklistData]="checklistGroup">
      </apex-inspection-checklist-info>
    </ng-container>

    <apex-checklist-group-checklist
      *ngIf="checklist"
      class="am"
      [checklist]="checklist">
    </apex-checklist-group-checklist>
    <apex-checklist-group-tree
      *ngIf="checklistGroup"
      class="am"
      [group]="checklistGroup"
      [showItems]="true"
      [hideHeader]="true">
    </apex-checklist-group-tree>
  </ng-container>

  <footer class="df r ac jsb">
    <button
      class="aml"
      mat-flat-button
      color="primary"
      routerLink="/info"
      [queryParams]="{ InspectionId: null }"
      queryParamsHandling="merge">
      <T str="Back"></T>
    </button>

    <button
      class="amr"
      mat-flat-button
      color="primary"
      (click)="next()"
      [disabled]="!checklist && !checklistGroup">
      <T str="Next"></T>
    </button>
  </footer>
</main>

<ng-template #loadingView>
  <div class="df c f1 ac jc">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
