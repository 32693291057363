import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { uniqBy } from 'lodash-es';
import { Case, Contractor } from 'projects/apex/src/app/models/case';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { t } from '../../../components/translate/translate.function';
import { CaseParams } from '../../../models/case-params';
import { FileUsage } from '../../../models/file-usage';
import { Inspection } from '../../../models/inspection';
import { Marking } from '../../../models/marking';
import { snackErr } from '../../../modules/snack.module';
import { transformQueryParams } from '../../../utils/functions';
import { CasesComponent } from '../../case/cases/cases.component';
import { InspectionService } from '../inspection.service';

@Component({
  selector: 'apex-inspection-case-page',
  templateUrl: './case.component.html',
})
export class CasePageComponent implements OnInit {
  @ViewChild('casesComponent') casesComponent: CasesComponent;

  readonly InspectionIdString = 'InspectionId';

  get title(): string {
    return t('Cases');
  }

  cases: Case[];

  public queryParams: CaseParams;
  public inspection: Inspection;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public inspectionService: InspectionService,
  ) {}

  ngOnInit(): void {
    const params = transformQueryParams(this.route?.snapshot?.queryParams);

    this.queryParams = Object.assign(
      {
        showOpen: true,
        showCaseManagers: true,
      },
      params,
    ) as CaseParams;

    if (this.queryParams[this.InspectionIdString]) {
      delete this.queryParams[this.InspectionIdString];
    }

    this.inspection = this.route?.snapshot?.data?.inspection;
  }

  next(): void {
    this.saveLocalInspection()
      .pipe(take(1))
      .subscribe(() => void this.router.navigate(['/', 'misc-info'], { queryParamsHandling: 'preserve' }));
  }

  saveLocalInspection(): Observable<Inspection> {
    return from(this.inspectionService.dexie.Inspections.get(this.inspection?.id)).pipe(
      catchError(() => of(undefined)),
      mergeMap((inspection: Inspection) => {
        if (!inspection) {
          snackErr(t('Problem saving your inspection locally'), new Error('Inspection is undefined'));

          throw new Error('Inspection is undefined');
        }

        inspection.data.caseIds = this.cases?.length ? this.cases.filter((c) => c.createdAt).map((c) => c.id) : [];
        inspection.data.Cases = this.cases;
        inspection.data.Contractors = this.cases
          ? this.getContractors()
          : this.inspection
            ? this.inspection.data.Contractors
            : [];
        inspection.data.showCompleted = !!this.queryParams.showCompleted;

        return this.inspectionService.saveLocalInspection(inspection);
      }),
      // @todo This is idiotic and will need to be fixed.
      tap((newInspection: Inspection) => {
        this.inspection = newInspection;
      }),
    );
  }

  getContractors(): Contractor[] {
    let contractors = [];

    this.cases?.forEach((c: Case) => {
      if (c.Contractor) {
        contractors = contractors.concat([c.Contractor]);
      }

      if (c?.Contractors?.length) {
        contractors = contractors.concat(c.Contractors);
      }
    });

    return uniqBy(contractors, 'id');
  }

  updateInspection(): void {
    if (this.inspection) {
      this.saveLocalInspection().pipe(take(1)).subscribe();
    }
  }

  updateMarking(marking: Marking): void {
    marking.FileUsage = null;
    marking.Case = null;

    const caseFromList = this.cases.find((c) => c.id === marking.modelId);

    if (caseFromList) {
      caseFromList.Markings = [marking];
      caseFromList.FileUsages.forEach((f: FileUsage) => {
        f.Markings = caseFromList.Markings.filter((m) => m.FileUsageId === f.id);
      });
      void this.inspectionService.dexie.Cases.put(caseFromList).then(() => {
        this.casesComponent.view.setCase(caseFromList.id);
        this.updateInspection();
      });
    }
  }

  get hasChecklist(): boolean {
    return !!(this.inspection?.data?.ChecklistId || this.inspection?.data?.ChecklistGroupId);
  }

  toggleHideOldCases(): void {
    this.casesComponent.cases = [];
    this.cases = [];

    this.updateInspection();
  }
}
