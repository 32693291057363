import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApartmentService } from 'projects/apex/src/app/features/apartment/apartment.service';
import { CaseCategoryService } from 'projects/apex/src/app/features/case/case-category.service';
import { ClientService } from 'projects/apex/src/app/features/client/client.service';
import { FieldService } from 'projects/apex/src/app/features/field/field.service';
import { Obj } from 'projects/apex/src/app/features/object/object.model';
import { ObjectService } from 'projects/apex/src/app/features/object/object.service';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { CaseCategory } from 'projects/apex/src/app/models/case-category';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { Observable, Subscription, forkJoin, from, of } from 'rxjs';
import { debounceTime, finalize, map, mergeMap, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { FileUsageService } from '../../../components/file-usage/file-usage.service';
import { t } from '../../../components/translate/translate.function';
import { Case } from '../../../models/case';
import { Field } from '../../../models/field';
import { Inspection } from '../../../models/inspection';
import { ObjectField } from '../../../models/object-field';
import { Project } from '../../../models/project';
import { snack, snackAct, snackErr } from '../../../modules/snack.module';
import { ObjectFieldService } from '../../object/project/field/object-field.service';
import { ProjectService } from '../../project/project.service';
import { InspectionService } from '../inspection.service';
import { InfoPageData } from './info.types';

@Component({
  selector: 'apex-inspection-info-page',
  templateUrl: './info.component.html',
})
export class InfoPageComponent implements OnInit, AfterViewInit, OnDestroy {
  get title(): string {
    return t('Inspection');
  }

  @ViewChild(NgForm) ngForm: NgForm;

  data: InfoPageData = {
    Category: null,
    Object: null,
    Project: null,
    Apartment: null,
    Client: null,
    InspectionManagerId: null,
    Field: null,
    ChecklistTemplate: null,
    ChecklistGroupTemplate: null,
    ObjectField: null,

    locale: 'nb',
  };

  profile: User;
  project: Project;

  subs: Subscription[] = [];
  savingOfflineData: boolean;
  savedInspections = [];

  caseManagers: User[] = [];
  fields: Field[] = [];
  navigating = false;

  constructor(
    private dialog: MatDialog,
    public route: ActivatedRoute,
    private router: Router,
    public inspectionService: InspectionService,
    private caseCategoryService: CaseCategoryService,
    private fieldService: FieldService,
    private objectService: ObjectService,
    private objectFieldService: ObjectFieldService,
    private projectService: ProjectService,
    private apartmentService: ApartmentService,
    private userService: UserService,
    private clientService: ClientService,
    private fileUsageService: FileUsageService,
  ) {}

  ngOnInit(): void {
    this.profile = this.route.snapshot.data.profile;
    this.data.InspectionManagerId = this.profile.id;

    this.data.locale = this.profile.locale ?? 'nb';

    this.subs = [
      this.route.queryParams.subscribe((queryParams) => {
        Object.keys(this.data).forEach((key) => {
          if (Number(queryParams[key]) && Number(queryParams[key]) !== this.data[key]) {
            this.data[key] = Number(queryParams[key]);
          }
        });

        this.data.locale = queryParams['locale'] ?? 'nb';

        const id = Number(queryParams.InspectionId);

        void this.inspectionService.dexie.Inspections.toArray().then((savedInspections) => {
          this.savedInspections = id ? savedInspections.filter((i) => i.id !== id) : savedInspections;
        });
      }),
    ];
  }

  ngAfterViewInit(): void {
    this.subs.push(
      this.ngForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        if (this.ngForm.form.dirty) {
          if (this.data.Object) {
            this.data.Field = null;
          }

          if (this.data.Project) {
            this.data.ObjectField = null;
          }

          void this.router.navigate([], { queryParams: this.data });
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  updateProject(ProjectId: number): void {
    if (!this.data.Project || !ProjectId || (this.project && this.project.id !== ProjectId)) {
      this.data.Apartment = null;
      this.data.Client = null;
    }

    if (ProjectId) {
      this.projectService
        .get(Number(ProjectId))
        .pipe(
          take(1),
          map((res) => res.Entity),
        )
        .subscribe((project) => {
          this.project = project;

          if (this.project.DefaultCaseManagerId) {
            this.data.InspectionManagerId = this.userService.profile.id ?? this.project.DefaultCaseManagerId;
          }
        });
    }
  }

  completeInspection(inspection: Inspection): void {
    if (inspection?.id) {
      if (inspection.ProjectId || inspection.data?.ObjectId) {
        this.inspectionService.completeInspection(inspection).subscribe({
          next: (savedInspection) => {
            const idx = this.savedInspections.map((si) => si.id).indexOf(inspection.id);

            if (idx !== -1) {
              this.savedInspections.splice(idx, 1);
            }

            snackAct(t('Inspection sent in'), {
              msg: t('Go to inspection', {
                _context: 'action',
              }),
              fn: () => {
                void this.router.navigate(['/', savedInspection.id]);
              },
            });
          },
          error: (err) => {
            snackErr(t('Could not send in inspection'), err);
          },
        });
      } else {
        snackAct(t('Invalid inspection'), {
          msg: t('Delete', {
            _context: 'action',
          }),
          fn: () => {
            void this.inspectionService?.dexie?.Inspections?.delete(inspection.id);

            const idx = this.savedInspections.map((si) => si.id).indexOf(inspection.id);

            if (idx !== -1) {
              this.savedInspections.splice(idx, 1);
            }
          },
        });
      }
    }
  }

  deleteSavedInspection(inspection: Inspection): void {
    if (inspection?.id) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: t('Are you sure you want to delete this inspection?'),
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            from(this.inspectionService.dexie.Cases.where('InspectionId').equals(inspection.id).toArray())
              .pipe(
                mergeMap((cases: Case[]) =>
                  forkJoin([
                    from(this.inspectionService.dexie.Inspections.delete(inspection.id)),
                    from(this.inspectionService.dexie.Cases.where('InspectionId').equals(inspection.id).delete()),
                    from(
                      this.inspectionService.dexie.Files.where('id')
                        .anyOf(...cases.map((c) => (c?.files ? c?.files : [])))
                        .delete(),
                    ),
                    this.inspectionService.deleteInspectionChecklistData(inspection),
                  ]),
                ),
                take(1),
              )
              .subscribe(() => {
                const idx = this.savedInspections.indexOf(inspection);

                if (idx !== -1) {
                  this.savedInspections.splice(idx, 1);
                }
              });
          }
        });
    }
  }

  setInspection(inspection: Inspection): void {
    const hasChecklist = inspection.data.ChecklistTemplateId || inspection.data.ChecklistGroupTemplateId;

    void this.router.navigate([hasChecklist ? '/checklist' : '/case'], {
      queryParams: {
        InspectionId: inspection.id,
        Object: inspection.data?.ObjectId ? inspection.data?.ObjectId : null,
        Project: inspection.ProjectId ? inspection.ProjectId : null,
        Apartment: inspection.ApartmentId ? inspection.ApartmentId : null,
        Category: inspection.data.CategoryId,
        Client: inspection.data.ClientId,
        // CaseManager: inspection.data.CaseManagerId,
        InspectionManagerId: inspection.data.CaseManagerId,
        Field: inspection.data.FieldId,
        ChecklistTemplate: inspection.data.ChecklistTemplateId,
        ChecklistGroupTemplate: inspection.data.ChecklistGroupTemplateId,
      },
    });
  }

  next(): void {
    this.navigating = true;

    this.createInspection()
      .pipe(
        finalize(() => {
          this.navigating = false;
        }),
      )
      .subscribe({
        next: (InspectionId: number) => {
          void this.router.navigate(
            [this.data.ChecklistTemplate || this.data.ChecklistGroupTemplate ? '/checklist' : '/case'],
            {
              queryParams: {
                InspectionId,
              },
              queryParamsHandling: 'merge',
            },
          );
        },
      });
  }

  createInspection(): Observable<number> {
    return forkJoin({
      caseCategory: this.caseCategory$,
      field: this.field$,
      object: this.object$,
      objectField: this.objectField$,
      project: this.project$,
      apartment: this.apartment$,
      caseManager: this.caseManager$,
      client: this.client$,
    }).pipe(
      map((res) => {
        const inspection = new Inspection();

        inspection.offline = this.inspectionService.offline;

        const data = {
          floorplans: [],
          inspectionDate: undefined,
          CaseManagerId: res.caseManager?.id,
          CaseManager: res.caseManager,
          ClientId: res.client?.id,
          Contractors: [],
          CategoryId: res.caseCategory.id,
          Category: res.caseCategory,
          FieldId: res.field?.id,
          Field: res.field,
          caseIds: [],
          Cases: [],
          ObjectId: res.object ? res.object.id : null,
          Object: res.object ? res.object : null,
          ObjectFieldId: res.objectField?.id ?? null,
          ObjectField: res.objectField,
          ChecklistTemplateId: this.data.ChecklistTemplate,
          ChecklistGroupTemplateId: this.data.ChecklistGroupTemplate,

          locale: this.data.locale,
        };

        if (inspection.data) {
          inspection.data = Object.assign(inspection.data, data);
        } else {
          inspection.data = data;
        }

        if (res.project) {
          inspection.ProjectId = res.project?.id;

          const project = res.project;

          inspection.Project = {
            id: project.id,
            name: project.name,
            CustomerId: project.CustomerId,
            Fields: project.Fields,
            CaseManagers: project.CaseManagers,
          };

          if (res.apartment) {
            const apartment = this.project?.Apartments.find((a) => a.id === res.apartment.id);

            if (apartment) {
              inspection.ApartmentId = apartment.id;
              inspection.Apartment = {
                id: apartment.id,
                ProjectId: this.project.id,
                name: apartment.name,
                Clients: apartment.Clients,
              };
            } else {
              const msg = t('Apartment does not exists on project');

              void this.router.navigate([], {
                queryParams: { Apartment: null },
                queryParamsHandling: 'merge',
              });
              snack(msg, { dur: 3000 });

              throw new Error(msg);
            }
          }
        }

        return inspection;
      }),
      mergeMap((inspection) => from(this.inspectionService.dexie.Inspections.add(inspection))),
    );
  }

  get caseCategory$(): Observable<CaseCategory> {
    return this.caseCategoryService
      .query()
      .pipe(map((caseCategories) => caseCategories.find((cc) => cc.id === Number(this.data.Category))));
  }

  get field$(): Observable<Field> {
    if (this.data.Field) {
      return this.fieldService.get(this.data.Field);
    }

    return of(null);
  }

  get object$(): Observable<Obj> {
    if (this.data.Object) {
      return this.objectService.get<Obj>(this.data.Object);
    }

    return of(null);
  }

  get objectField$(): Observable<ObjectField | null> {
    if (!!this.data.Object && !!this.data.ObjectField) {
      return this.objectFieldService.get(this.data.Object, this.data.ObjectField);
    }

    return of(null);
  }

  get project$(): Observable<Project> {
    if (this.data.Project) {
      return this.projectService.get(this.data.Project).pipe(map((res) => res.Entity));
    }

    return of(null);
  }

  get apartment$(): Observable<Apartment> {
    if (this.data.Apartment) {
      return this.apartmentService.get(this.data.Apartment);
    }

    return of(null);
  }

  get caseManager$(): Observable<User> {
    return this.userService.get(this.data.InspectionManagerId);
  }

  get client$(): Observable<User> {
    if (this.data.Client) {
      return this.clientService.get(this.data.Client).pipe(map((res) => res.Entity));
    }

    return of(null);
  }

  async updateObjectFieldOrField(): Promise<void> {
    await this.router.navigate([], {
      queryParams: this.data,
    });
  }
}
