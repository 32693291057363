import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUsageViewerData } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { Checklist } from 'projects/apex/src/app/features/checklist/checklist.model';
import { ChecklistService } from 'projects/apex/src/app/features/checklist/checklist.service';
import { CaseCategory } from 'projects/apex/src/app/models/case-category';
import { ChecklistGroup } from 'projects/apex/src/app/models/checklist-group';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import {
  Inspection,
  InspectionInvolvedUser,
  InspectionInvolvedUserType,
  InspectionSignatureType,
} from 'projects/apex/src/app/models/inspection';
import { Marking } from 'projects/apex/src/app/models/marking';
import { Observable, Subscription, forkJoin, of, skipWhile } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../components/confirm-dialog/confirm-dialog.types';
import { FileUsageComponent } from '../../../components/file-usage/file-usage.component';
import { t } from '../../../components/translate/translate.function';
import { Case } from '../../../models/case';
import { File as ApexFile } from '../../../models/file';
import { snack, snackErr } from '../../../modules/snack.module';
import { filterObjectToQuery, quickDate } from '../../../utils/functions';
import { ChecklistGroupService } from '../../checklist-group/checklist-group.service';
import { InspectionNestService } from '../inspection-nest.service';
import { InspectionService } from '../inspection.service';

@Component({
  selector: 'apex-inspection-view-page',
  templateUrl: './view.component.html',
})
export class InspectionViewPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('miscInfoFiles', { static: false }) miscInfoFiles: FileUsageComponent;

  get title(): string {
    return t('{dateOrSummary} - {categoryName}', {
      dateOrSummary: this.inspection?.data?.inspectionDate
        ? quickDate(this.inspection?.data?.inspectionDate, 'dd.MM.yy HH:mm')
        : t('Summary'),
      // @note these translations are handled by caseCategories
      categoryName: t(this.inspection?.data?.Category?.name),
    });
  }

  cases: Case[] = [];
  floorplans: FileUsage[] = [];

  inspection: Inspection;
  fileUsageViewerData: FileUsageViewerData;
  caseCategories: CaseCategory[] = [];

  caseCategoryName: string;
  signatureTypeName: string;

  checklist: Checklist;
  checklistGroup: ChecklistGroup;

  users: InspectionInvolvedUser[];
  others: InspectionInvolvedUser[];

  fileUsages: FileUsage[] = [];
  markings: Marking[] = [];

  savedAttachments: FileUsage[] = [];

  highlightedCaseIds: number[] = [];

  ongoing: boolean;

  subs: Subscription[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fileUsageService: FileUsageService,
    private checklistService: ChecklistService,
    private checklistGroupService: ChecklistGroupService,
    public inspectionService: InspectionService,
    private dialog: MatDialog,
    private inspectionNestService: InspectionNestService,
  ) {}

  ngOnInit(): void {
    this.subs = [
      this.route.data.subscribe((data) => {
        if (data.inspection) {
          this.inspection = data.inspection;
        } else {
          this.inspection = data.savedInspection;
          this.ongoing = true;
        }

        if (this.inspection) {
          this.getCasesFromInspection(this.inspection);
          this.getChecklistFromInspection();
          this.sortUserList();
          this.signatureTypeName = this.getSignatureTypeName(this.inspection.data.signatureType);
        } else {
          void this.router.navigate(['/', 'info']);
        }
      }),
    ];
  }

  ngAfterViewInit(): void {
    if (this.ongoing && this.inspection?.data?.Files) {
      setTimeout(() => {
        this.miscInfoFiles.fileUsages = this.inspection.data.Files.map((f) => this.getDummyFileUsageFromFile(f));
      });
    }
  }

  getCasesFromInspection(inspection: Inspection): void {
    this.cases = inspection?.data?.Cases ?? [];
    this.caseCategories = [];
    this.cases.forEach((c) => {
      this.caseCategories.push(c.CaseCategory);
    });
    this.getFloorplanFromInspection();
  }

  getFloorplanFromInspection(): void {
    forkJoin(
      [].concat(
        this.inspection?.ProjectId
          ? this.fileUsageService.all('project', this.inspection.ProjectId, 'floorplans')
          : of([]),
        this.inspection?.ApartmentId
          ? this.fileUsageService.all('apartment', this.inspection.ApartmentId, 'floorplans')
          : of([]),
        this.inspection?.data?.ObjectId
          ? this.fileUsageService.all('object', this.inspection.data.ObjectId, 'floorplans')
          : of([]),
      ),
    )
      .pipe(map((f: FileUsage[][]) => [].concat(...f)))
      .subscribe({
        next: (fileUsages: FileUsage[]) => {
          const caseFloorplans: number[] = [].concat(...this.cases.map((c) => c?.Markings?.map((m) => m.FileUsageId)));

          this.floorplans = fileUsages.filter((f) => !!(f && caseFloorplans.find((cfp) => cfp && cfp === f.id)));
          this.cases.forEach((c) => {
            c.Floorplans = c.Floorplans
              ? c.Floorplans.filter((f) => !!(f && caseFloorplans.find((cfp) => cfp && cfp === f.id)))
              : [];
          });
        },
      });
  }

  getChecklistFromInspection(): void {
    forkJoin({
      checklist: this.checklist$,
      checklistGroup: this.checklistGroup$,
    }).subscribe({
      next: (res) => {
        this.checklist = res.checklist;
        this.checklistGroup = res.checklistGroup;
      },
    });
  }

  sortUserList(): void {
    const currentUser = this.inspection.data.Users.find((u) => u.type === InspectionInvolvedUserType.CurrentUser);
    const caseManager = this.inspection.data.Users.find((u) => u.type === InspectionInvolvedUserType.CaseManager);
    const contractors = this.inspection.data.Users.filter((u) => u.type === InspectionInvolvedUserType.Contractor);
    const clients = this.inspection.data.Users.filter((u) => u.type === InspectionInvolvedUserType.Client);

    this.others = this.inspection.data.Users.filter((u) => u.type === undefined);
    this.users = (currentUser ? [currentUser] : []).concat(caseManager, contractors, clients);
  }

  getSignatureTypeName(signatureType: InspectionSignatureType): string {
    switch (signatureType) {
      case InspectionSignatureType.BankId:
        return 'BankID';
      case InspectionSignatureType.Handwritten:
        return 'Handwritten';
      case InspectionSignatureType.None:
        return 'None';
    }
  }

  saveInspection(): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to finish this inspection?'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.inspection.data.inspectionDate = new Date();
          this.inspectionService.saveLocalInspection(this.inspection).subscribe(() => {
            this.newInspection();
          });
        }
      });
  }

  completeInspection(): void {
    this.subs.push(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: t('Are you sure you want to finish this inspection?'),
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.inspection.data.inspectionDate = new Date();
            this.inspectionService.completeInspection(this.inspection).subscribe({
              next: (res) => {
                snack(t('Inspection sent in'));
                void this.router.navigate(['/', res.id]);
              },
              error: (err) => {
                snackErr(t('Could not send in inspection'), err);
              },
            });
          }
        }),
    );
  }

  getDummyFileUsageFromFile(f: ApexFile): FileUsage {
    const fileUsage = new FileUsage();

    fileUsage.FileId = f.id;
    fileUsage.File = f;
    fileUsage.self = 'inspection';
    fileUsage.name = 'misc-info';
    fileUsage.fileName = f.name;
    fileUsage.isNew = true;

    return fileUsage;
  }

  newInspection(): void {
    const { CategoryId, ObjectId, CaseManagerId, FieldId, ChecklistTemplateId, ChecklistGroupTemplateId, locale } =
      this.inspection.data;
    const ProjectId = this.inspection.ProjectId;

    const queryParams = {
      Category: CategoryId,
      Project: ProjectId,
      Object: ObjectId,
      InspectionManagerId: CaseManagerId,
      Field: FieldId,
      ChecklistTemplate: ChecklistTemplateId,
      ChecklistGroupTemplate: ChecklistGroupTemplateId,
      locale,
    };

    const queryParamsString = filterObjectToQuery(queryParams);

    window.location.href = `${environment.inspectionUrl}/info?${queryParamsString}`;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s?.unsubscribe());
  }

  get checklist$(): Observable<Checklist> {
    return this.inspection?.data?.ChecklistId
      ? this.checklistService.get(this.inspection.data.ChecklistId).pipe(map((e) => e.Entity))
      : of(null);
  }

  get checklistGroup$(): Observable<ChecklistGroup> {
    return this.inspection?.data?.ChecklistGroupId
      ? this.checklistGroupService.get(this.inspection.data.ChecklistGroupId).pipe(map((e) => e.Entity))
      : of(null);
  }

  async deleteInspection(inspection: Inspection): Promise<void> {
    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to delete this inspection?'),
          del: true,
        },
      })
      .afterClosed()
      .pipe(skipWhile((result) => !result))
      .subscribe({
        next: async () => {
          await this.inspectionNestService.delete(inspection.id);

          snack(t('Inspection deleted'));

          if (inspection.ObjectId !== null) {
            return void this.router.navigate(['/', 'object', 'project', inspection.ObjectId, 'inspection']);
          }

          return void this.router.navigate(['/', 'project', inspection.ProjectId, 'apartment', inspection.ApartmentId]);
        },
      });
  }
}
