import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { InspectionService } from 'projects/apex/src/app/features/inspection/inspection.service';
import { snackErr } from 'projects/apex/src/app/modules/snack.module';
import { SwService } from 'projects/apex/src/app/services/sw/sw.service';
import { locale } from 'projects/apex/src/app/utils/functions';
import { environment } from 'projects/apex/src/environments/environment';
import { TranslationService } from '../../../apex/src/app/components/translate/translation.service';

@Component({
  selector: 'inspection-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private inspectionService: InspectionService,
    private titleService: Title,
    private translationService: TranslationService,
    public sw: SwService,
  ) {
    this.titleService.setTitle(`${t('Inspection')} - APEX`);
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.assetsUrl}/icons.svg`),
    );

    void this.translationService.init({
      token: environment.transifexToken,
    });

    this.registerServiceWorker();
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.translationService.setCurrentLocale(locale());
    } catch (err) {
      snackErr(t('Failed to get translations for {locale}', { locale: locale() }), err);
    }
  }

  registerServiceWorker(): void {
    if (typeof Worker !== 'undefined' && 'serviceWorker' in navigator) {
      if (environment.production) {
        this.sw.init();
        this.inspectionService.sw = true;
      } else {
        navigator.serviceWorker.register('/service-worker.js').then(
          () => {
            this.inspectionService.sw = true;
          },
          () => {
            this.inspectionService.sw = false;
          },
        );
      }
    } else {
      this.inspectionService.sw = false;
    }
  }
}
