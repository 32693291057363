import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HandwrittenSigningComponent } from 'projects/apex/src/app/components/handwritten-signing/handwritten-signing.component';
import { HandwrittenSigningResult } from 'projects/apex/src/app/components/handwritten-signing/handwritten-signing.types';
import {
  Inspection,
  InspectionInvolvedUser,
  InspectionInvolvedUserType,
  InspectionSignature,
  InspectionSignatureType,
} from 'projects/apex/src/app/models/inspection';
import { Observable } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { InspectionService } from '../inspection.service';
import { AddOtherUserDialogComponent } from './add-other-user-dialog.component';
import { InvolvedUser } from './involved.types';

@Component({
  selector: 'apex-inspection-involved-page',
  templateUrl: './involved.component.html',
})
export class InvolvedPageComponent implements OnInit {
  get title(): string {
    return t('Involved');
  }

  InspectionSignatureType = InspectionSignatureType;
  InspectionInvolvedUserType = InspectionInvolvedUserType;

  profile: InvolvedUser;
  inspection: Inspection;

  signatureType = InspectionSignatureType.None;
  invalid = true;

  caseManager: InvolvedUser;
  contractors: InvolvedUser[] = [];
  clients: InvolvedUser[] = [];

  involvedUsers: InvolvedUser[] = [];
  others: InvolvedUser[] = [];

  constructor(
    public inspectionService: InspectionService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const data = this.route.snapshot.data;

    this.inspection = this.route.snapshot.data.inspection;
    this.profile = data.profile as InvolvedUser;

    this.profile.type = InspectionInvolvedUserType.CurrentUser;
    this.profile.present = true;
    this.profile.report = true;

    if (this.inspection.Project && this.inspection.Apartment?.Clients?.length) {
      this.clients = this.clients.concat(this.inspection.Apartment.Clients as InvolvedUser[]);
    }

    this.clients = this.clients?.filter((client, i, self) => self.map((s) => s.id).indexOf(client.id) === i) ?? [];
    this.contractors = (this.inspection.data.Contractors as InvolvedUser[]) ?? [];
    this.contractors =
      this.contractors?.filter((contractor, i, self) => self.map((s) => s?.id).indexOf(contractor?.id) === i) ?? [];

    if (this.inspection.data.CaseManager) {
      this.caseManager = this.inspection.data.CaseManager as InvolvedUser;
      this.caseManager.type = InspectionInvolvedUserType.CaseManager;
      this.caseManager.present = true;
      this.caseManager.report = true;
    }

    if (this.contractors?.length) {
      this.contractors.forEach((c) => {
        if (c) {
          c.type = InspectionInvolvedUserType.Contractor;
        }
      });
    }

    if (this.clients?.length) {
      this.clients.forEach((c) => {
        if (c) {
          c.type = InspectionInvolvedUserType.Client;
        }
      });
    }

    this.involvedUsers = this.involvedUsers.concat(this.caseManager, this.contractors, this.clients);

    if (this.caseManager && this.caseManager.id !== this.profile.id) {
      this.involvedUsers.unshift(this.profile);
    }

    this.checkForSavedValues();
    this.checkIfValid();
  }

  back(): void {
    this.updateInspection().subscribe(() => {
      void this.router.navigate(['/misc-info'], { queryParamsHandling: 'preserve' });
    });
  }

  checkIfValid(): void {
    let invalid = false;

    this.involvedUsers.forEach((user) => {
      if (
        this.signatureType === InspectionSignatureType.BankId || this.signatureType === InspectionSignatureType.None
          ? false
          : user.signer
            ? !user.signature
            : false
      ) {
        invalid = true;
      }
    });
    this.others.forEach((user) => {
      if (
        this.signatureType === InspectionSignatureType.BankId || this.signatureType === InspectionSignatureType.None
          ? false
          : user.signer
            ? !user.signature
            : false
      ) {
        invalid = true;
      }
    });
    this.invalid = invalid;
    // this.updateInspection()
    // .pipe(
    //   take(1)
    // )
    // .subscribe();
  }

  checkForSavedValues(): void {
    if (this.inspection.data.Users) {
      this.involvedUsers.forEach((involvedUser) => {
        const user = this.inspection.data.Users.find((u) => u.id === involvedUser.id);

        if (user) {
          involvedUser.present = user.present;
          involvedUser.report = user.report;
          involvedUser.signer = user.signer;
          involvedUser.signatureId = user.signatureId;
        }
      });
      this.others = this.inspection.data.Users.filter((u) => !u?.type) as InvolvedUser[];

      if (this.inspection.signatures && this.inspection.signatures.length) {
        [].concat(this.involvedUsers, this.others).forEach((user) => {
          const inspectionSignature = this.inspection.signatures.find((s) => s.id === user.signatureId);

          if (inspectionSignature) {
            user.signature = inspectionSignature.signature;
          }
        });
      }
    }

    this.signatureType = this.inspection.data.signatureType
      ? this.inspection.data.signatureType
      : InspectionSignatureType.None;
  }

  setSignatureType(type: InspectionSignatureType): void {
    switch (type) {
      case InspectionSignatureType.None:
        [].concat(this.involvedUsers, this.others).forEach((u) => {
          u.signer = false;
        });
        break;

      case InspectionSignatureType.BankId:
        [].concat(this.involvedUsers, this.others).forEach((u) => {
          if (!u.mail || u.mail === '') {
            u.signer = false;
          }
        });
        break;
    }

    this.signatureType = type;
  }

  sign(user: InvolvedUser): void {
    this.dialog
      .open(HandwrittenSigningComponent, {
        data: {
          user,
          signature: user.signature ? user.signature : undefined,
          width: 1400,
          height: 400,
          quality: 0.1,
          lineWidth: 6,
        },
      })
      .afterClosed()
      .subscribe((result: HandwrittenSigningResult) => {
        if (result) {
          if (result.delete) {
            delete user.signature;
          } else if (result.signature) {
            user.signature = result.signature;
          }

          this.checkIfValid();
        }
      });
  }

  addOtherUser(): void {
    this.dialog
      .open(AddOtherUserDialogComponent, { data: { signatureType: this.signatureType } })
      .afterClosed()
      .subscribe((user: InvolvedUser) => {
        if (user && user.name) {
          this.others.push(user);
          this.checkIfValid();
        }
      });
  }

  updateInspection(): Observable<Inspection> {
    const users = [];
    const signatures: InspectionSignature[] = [];
    let signatureId = 0;

    this.involvedUsers.concat(this.others).forEach((u: InvolvedUser) => {
      if (!u) {
        return;
      }

      const user = new InspectionInvolvedUser();

      user.id = u.id;
      user.name = u.name;
      user.mail = u.mail;
      user.type = u.type;
      user.present = u.present;
      user.report = u.report;
      user.signer = u.signer;

      if (u.signer && u.signature) {
        const signature = new InspectionSignature();

        signature.id = signatureId;
        signature.signature = u.signature;
        user.signatureId = signatureId;
        signatures.push(signature);
        signatureId++;
      }

      users.push(user);
    });
    this.inspection.data.Users = users.filter((u) => u.present || u.signer || u.report);
    this.inspection.signatures = signatures;
    this.inspection.data.signatureType = this.signatureType;

    return this.inspectionService.saveLocalInspection(this.inspection);
  }

  next(): void {
    this.updateInspection().subscribe(() => {
      void this.router.navigate(['summary'], { queryParamsHandling: 'preserve' });
    });
  }
}
