<ng-container
  *ngIf="inspection"
  xmlns="http://www.w3.org/1999/html">
  <header>
    <apex-page-info [title]="title">
      <div
        *ngIf="inspectionService.sw"
        class="df f1 jfe">
        <button
          mat-icon-button
          [disabled]="true">
          <mat-icon *ngIf="!inspectionService.savingOfflineData">
            {{ inspectionService.offline ? 'wifi_off' : 'wifi' }}
          </mat-icon>
          <mat-spinner
            *ngIf="inspectionService.savingOfflineData"
            color="warn"
            [diameter]="24">
          </mat-spinner>
        </button>
      </div>
    </apex-page-info>
  </header>
  <main>
    <section>
      <div class="mat-elevation-z2 ap">
        <apex-box
          expanded
          class="db amb no-padding">
          <T
            title
            str="Notes"></T>

          <editor
            [init]="{ placeholder: 'Notes' | translate } | editorInit"
            name="notes"
            [(ngModel)]="notes">
          </editor>
        </apex-box>

        <apex-file-usage
          #miscInfoFiles
          self="inspection"
          [selfId]="0"
          name="misc-info"
          [title]="'Attachments' | translate"
          (fuChange)="save($event)">
        </apex-file-usage>
      </div>
    </section>
    <footer class="df jsb ac">
      <button
        class="aml"
        mat-flat-button
        color="primary"
        (click)="save(); back()">
        <T str="Back"></T>
      </button>

      <button
        class="amr"
        mat-flat-button
        color="primary"
        (click)="save(); next()">
        <T str="Next"></T>
      </button>
    </footer>
  </main>
</ng-container>
