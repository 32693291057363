<h1 mat-dialog-title>
  <T str="Add user"></T>
</h1>
<mat-dialog-content>
  <main>
    <div>
      <apex-autocomplete
        type="User"
        [label]="'Add APEX user' | translate"
        (modelChange)="addApexUser($event)">
      </apex-autocomplete>
    </div>
    <form
      *ngIf="form"
      [formGroup]="form">
      <table class="apex phone tablet">
        <thead>
          <tr>
            <th class="f4">
              <T str="Name"></T>
            </th>
            <th class="f4">
              <T str="Email"></T>
            </th>
            <th class="jc">
              <T str="Present"></T>
            </th>
            <th class="jc">
              <T str="Report"></T>
            </th>
            <th class="jc">
              <T str="Signature"></T>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="f4">
              <label>
                <T str="Name"></T>
              </label>
              <mat-form-field>
                <input
                  matInput
                  formControlName="name" />
              </mat-form-field>
            </td>
            <td class="f4">
              <label>
                <T str="Email"></T>
              </label>
              <mat-form-field>
                <input
                  matInput
                  formControlName="mail" />
              </mat-form-field>
            </td>
            <td class="jc">
              <label>
                <T str="Present"></T>
              </label>
              <mat-slide-toggle formControlName="present"></mat-slide-toggle>
            </td>
            <td class="jc">
              <label>
                <T str="Report"></T>
              </label>
              <mat-slide-toggle formControlName="report"></mat-slide-toggle>
            </td>
            <td class="jc">
              <label>
                <T str="Signature"></T>
              </label>
              <mat-slide-toggle formControlName="signer"></mat-slide-toggle>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </main>
</mat-dialog-content>
<mat-dialog-actions class="df r jfe">
  <button
    mat-button
    (click)="cancel()">
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="confirm()"
    color="primary"
    [disabled]="!form || form.invalid">
    <T str="Confirm"></T>
  </button>
</mat-dialog-actions>
