<header>
  <apex-page-info [title]="title">
    <div class="df f1 jfe ac">
      <ng-container *ngIf="!inspectionService.offline">
        <button
          mat-icon-button
          type="button"
          [matTooltip]="'Hide existing cases' | translate"
          (click)="toggleHideOldCases()">
          <mat-icon> playlist_remove</mat-icon>
        </button>
      </ng-container>
      <button
        *ngIf="inspectionService.sw"
        mat-icon-button
        [disabled]="true">
        <mat-icon *ngIf="!inspectionService.savingOfflineData">
          {{ inspectionService.offline ? 'wifi_off' : 'wifi' }}
        </mat-icon>
        <mat-spinner
          *ngIf="inspectionService.savingOfflineData"
          color="warn"
          [diameter]="24">
        </mat-spinner>
      </button>
    </div>
  </apex-page-info>
</header>
<main class="f1">
  <apex-cases
    #casesComponent
    dialogClass="inspection"
    [standardParams]="queryParams"
    [highlightedCaseIds]="inspection?.data?.newCaseIds ? inspection.data?.newCaseIds : []"
    (casesChange)="cases = $event; inspection ? updateInspection() : null"
    (markingChange)="updateMarking($event)"
    [showCalendar]="false">
  </apex-cases>
  <footer class="df ac jsb">
    <button
      class="aml"
      mat-flat-button
      color="primary"
      [routerLink]="hasChecklist ? '/checklist' : '/info'"
      [queryParams]="{ InspectionId: hasChecklist ? inspection.id : null }"
      queryParamsHandling="merge">
      <T str="Back"></T>
    </button>

    <button
      class="amr"
      mat-flat-button
      color="primary"
      (click)="next()"
      [disabled]="!inspection || (!inspection.ProjectId && !inspection.data.ObjectId)">
      <T str="Next"></T>
    </button>
  </footer>
</main>
