import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { TranslateModule } from '../translate/translate.module';
import { ProjectObjectFieldSelectorComponent } from './project-object-field-selector.component';

@NgModule({
  imports: [CommonModule, FormsModule, AutocompleteModule, TranslateModule, MatFormFieldModule, MatInputModule],
  exports: [ProjectObjectFieldSelectorComponent],
  declarations: [ProjectObjectFieldSelectorComponent],
  providers: [],
})
export class ProjectObjectFieldSelectorModule {}
