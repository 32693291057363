<mat-expansion-panel
  *ngIf="checklistData"
  [expanded]="expanded">
  <mat-expansion-panel-header class="new bg-color-main">
    <mat-panel-title>
      <div class="df ac">
        <mat-icon
          *ngIf="isChecklist"
          [matTooltip]="'Checklist' | translate">
          checklist
        </mat-icon>
        <mat-icon
          *ngIf="isChecklistGroup"
          [matTooltip]="'Checklist group' | translate">
          list_alt
        </mat-icon>
        <span class="aml"> {{ checklistData.name }} </span>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <section class="df afs w">
    <div class="df c ams f1 p-minw-40">
      <span class="mat-caption ambxs">
        <T str="Name"></T>
      </span>
      {{ checklistData.name }}
    </div>

    <div
      class="df c ams f1 p-minw-40"
      *ngIf="checklistData.description">
      <span class="mat-caption ambxs">
        <T str="Description"></T>
      </span>
      <div [innerHTML]="checklistData.description"></div>
    </div>

    <div
      class="df c ams f1 p-minw-40"
      *ngIf="checklistData.Object">
      <span class="mat-caption ambxs">
        <T str="Object"></T>
      </span>
      {{ checklistData.Object.name }}
    </div>

    <ng-container *ngIf="checklistData.Object && checklistData.ObjectField">
      <div class="df c ams f1 p-minw-40">
        <span class="mat-caption ambxs">
          <T str="Discipline"> </T>
        </span>
        {{ checklistData.ObjectField.fullName }}
      </div>
    </ng-container>

    <div
      class="df c ams f1 p-minw-40"
      *ngIf="checklistData.Project">
      <span class="mat-caption ambxs">
        <T str="Project"></T>
      </span>
      {{ checklistData.Project.name }}
    </div>

    <div
      class="df c ams f1 p-minw-40"
      *ngIf="checklistData.Apartment">
      <span class="mat-caption ambxs">
        <T str="Unit"></T>
      </span>
      {{ checklistData.Apartment.name }}
    </div>

    <ng-container *ngIf="checklistData.Project && checklistData.Field">
      <div class="df c ams f1 p-minw-40">
        <span class="mat-caption ambxs">
          <T str="Discipline"></T>
        </span>
        {{ checklistData.Field.name }}
      </div>
    </ng-container>

    <div
      class="df c ams f1 p-minw-40"
      *ngIf="caseCategory$ | async as category">
      <span class="mat-caption ambxs">
        <T str="Category"></T>
      </span>
      {{ category.name }}
    </div>

    <div
      class="df c ams f1 p-minw-40"
      *ngIf="checklistData.CaseManager">
      <span class="mat-caption ambxs">
        <T str="Case manager"></T>
      </span>
      {{ checklistData.CaseManager.name }}
    </div>
  </section>
</mat-expansion-panel>
