<ng-container *ngIf="isObject || (!isObject && !isProject)">
  <apex-autocomplete
    name="Field"
    [ngModel]="model ? model.ObjectFieldId : data.ObjectField"
    (ngModelChange)="model ? model.ObjectFieldId : (data.ObjectField = $event)"
    type="ObjectField"
    [label]="'Field' | translate"
    [params]="{
      ObjectId: model ? model.ObjectId : data.Object,
    }"
    [hint]="hint"
    [showHint]="!isObject && !isProject"
    (modelChange)="emitModelChange($event)"
    [disabled]="!isObject">
  </apex-autocomplete>
</ng-container>
<ng-container *ngIf="isProject">
  <apex-autocomplete
    name="Field"
    [ngModel]="model ? model.FieldId : data.Field"
    (ngModelChange)="model ? model.FieldId : (data.Field = $event)"
    type="Field"
    [label]="'Field' | translate"
    (modelChange)="emitModelChange($event)"
    [params]="{
      ProjectId: model ? model.ProjectId : data.Project,
    }">
  </apex-autocomplete>
</ng-container>
