import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, fromEvent } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { User } from '../../models/user';
import { HandwrittenSigningData } from './handwritten-signing.types';

@Component({
  selector: 'apex-handwritten-signing',
  templateUrl: './handwritten-signing.component.html',
})
export class HandwrittenSigningComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() width = 1920;
  @Input() height = 1080;
  @Input() lineWidth = 8;
  @Input() quality = 0.7;

  @Input() signature: string;
  @Input() user: User;

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  readonly underlineOffsetHorizontal = 30; // in pixels
  readonly underlineOffsetBottom = 0.2; // In percent

  sub: Subscription;
  lastPos: { x: number; y: number };
  dirty = false;
  delete = false;

  public context: CanvasRenderingContext2D;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: HandwrittenSigningData,
    @Optional() public dialogRef: MatDialogRef<HandwrittenSigningComponent>,
  ) {}

  ngOnInit(): void {
    document.getElementsByTagName('html')?.[0]?.classList.add('overscroll-contain-none');

    this.dialogRef?.addPanelClass(['apex-fullscreen-dialog', 'phone', 'tablet']);

    if (this.data) {
      Object.assign(this, this.data);
    }
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('html')?.[0]?.classList.remove('overscroll-contain-none');
  }

  ngAfterViewInit(): void {
    this.context = (this.canvas.nativeElement as HTMLCanvasElement).getContext('2d');

    this.sub = fromEvent(this.canvas.nativeElement, 'mousedown')
      .pipe(
        mergeMap(() => {
          this.lastPos = undefined;

          const viewportOffset = this.canvas.nativeElement.getBoundingClientRect();
          const top = viewportOffset.top;
          const left = viewportOffset.left;

          return fromEvent(this.canvas.nativeElement, 'mousemove').pipe(
            map((e: MouseEvent) => ({
              x: (e.x - left) * (this.canvas.nativeElement.width / this.canvas.nativeElement.offsetWidth),
              y: (e.y - top) * (this.canvas.nativeElement.height / this.canvas.nativeElement.offsetHeight),
            })),
            takeUntil(fromEvent(document, 'mouseup')),
          );
        }),
      )
      .subscribe((e) => {
        this.draw(e);
      });
    fromEvent(this.canvas.nativeElement, 'mouseout').subscribe(() => {
      this.lastPos = undefined;
    });

    this.sub = fromEvent(this.canvas.nativeElement, 'touchstart')
      .pipe(
        mergeMap(() => {
          this.lastPos = undefined;

          const viewportOffset = this.canvas.nativeElement.getBoundingClientRect();
          const top = viewportOffset.top;
          const left = viewportOffset.left;

          return fromEvent(this.canvas.nativeElement, 'touchmove').pipe(
            map((e: TouchEvent) => {
              e.preventDefault();
              e.stopPropagation();

              return {
                x:
                  (e.touches[0].clientX - left) *
                  (this.canvas.nativeElement.width / this.canvas.nativeElement.offsetWidth),
                y:
                  (e.touches[0].clientY - top) *
                  (this.canvas.nativeElement.height / this.canvas.nativeElement.offsetHeight),
              };
            }),
            takeUntil(fromEvent(document, 'touchend')),
          );
        }),
      )
      .subscribe((e) => {
        this.draw(e);
      });

    this.resize();
  }

  drawUnderline(): void {
    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.width, this.height);
    this.context.fillStyle = 'black';
    this.context.beginPath();
    this.context.lineJoin = 'round';
    this.context.lineCap = 'round';
    this.context.moveTo(
      this.underlineOffsetHorizontal,
      this.canvas.nativeElement.height - this.canvas.nativeElement.height * this.underlineOffsetBottom,
    );
    this.context.lineTo(
      this.canvas.nativeElement.width - this.underlineOffsetHorizontal,
      this.canvas.nativeElement.height - this.canvas.nativeElement.height * this.underlineOffsetBottom,
    );
    this.context.lineWidth = this.lineWidth;
    this.context.stroke();
  }

  draw(newPos: { x: number; y: number }): void {
    if (!this.data?.viewMode) {
      this.delete = false;

      if (this.lastPos) {
        this.context.beginPath();
        this.context.lineJoin = 'round';
        this.context.lineCap = 'round';
        this.context.moveTo(this.lastPos.x, this.lastPos.y);
        this.context.lineTo(newPos.x, newPos.y);
        this.context.lineWidth = this.lineWidth;
        this.context.stroke();
        this.dirty = true;
      }

      this.lastPos = newPos;
    }
  }

  clear(): void {
    if (this.signature) {
      this.delete = true;
    }

    this.dirty = false;
    this.context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.drawUnderline();
  }

  confirm(): void {
    if (this.delete) {
      this.dialogRef.close({ delete: true });
    } else if (this.dirty) {
      this.dialogRef.close({
        delete: false,
        signature: this.canvas.nativeElement.toDataURL('image/jpeg', this.quality),
      });
    }
  }

  resize(): void {
    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;

    if (this.signature) {
      const img = new Image();

      img.onload = (): void => {
        this.context.drawImage(img, 0, 0);
      };

      img.src = this.signature;
    } else {
      this.drawUnderline();
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
