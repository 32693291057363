import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '../translate/translate.module';

import { HandwrittenSigningComponent } from './handwritten-signing.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [HandwrittenSigningComponent],
  declarations: [HandwrittenSigningComponent],
  providers: [],
})
export class HandwrittenSigningModule {}
