import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';
import { CasePageComponent } from 'projects/apex/src/app/features/inspection/case/case.component';
import { ChecklistPageComponent } from 'projects/apex/src/app/features/inspection/checklist/checklist.component';
import { InfoPageComponent } from 'projects/apex/src/app/features/inspection/info/info.component';
import { InspectionGuard } from 'projects/apex/src/app/features/inspection/inspection.guard';

import { InvolvedPageComponent } from 'projects/apex/src/app/features/inspection/involved/involved.component';
import { MiscInfoPageComponent } from 'projects/apex/src/app/features/inspection/misc-info/misc-info.component';
import { InspectionViewPageComponent } from 'projects/apex/src/app/features/inspection/view/view.component';
import { GetProfileResolver } from 'projects/apex/src/app/services/user/profile.resolve';
import {
  InspectionGetResolver,
  InspectionGetSavedByQueryParamResolver,
} from '../../../apex/src/app/features/inspection/inspection.resolve';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../../apex/src/app/features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: '',
    resolve: {
      profile: GetProfileResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'info',
        pathMatch: 'full',
      },
      {
        path: 'info',
        component: InfoPageComponent,
      },
      {
        path: '',
        canActivate: [InspectionGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          inspection: InspectionGetSavedByQueryParamResolver,
        },
        children: [
          {
            path: 'checklist',
            component: ChecklistPageComponent,
          },
          {
            path: 'case',
            component: CasePageComponent,
          },
          {
            path: 'misc-info',
            component: MiscInfoPageComponent,
          },
          {
            path: 'involved',
            component: InvolvedPageComponent,
          },
        ],
      },
      {
        path: 'summary',
        component: InspectionViewPageComponent,
        resolve: {
          savedInspection: InspectionGetSavedByQueryParamResolver,
        },
      },
      {
        path: ':id',
        component: InspectionViewPageComponent,
        resolve: {
          inspection: InspectionGetResolver,
        },
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: 'logout',
    children: [],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
