<ng-template
  #row
  let-user>
  <tr [ngClass]="user?.type ? user.type : 'other'">
    <td class="f1 jc">
      <mat-icon
        *ngIf="user && user.type === 'casemanager'"
        svgIcon="casemanager"></mat-icon>
      <mat-icon
        *ngIf="user && user.type === 'contractor'"
        svgIcon="contractor"></mat-icon>
      <mat-icon
        *ngIf="user && user.type === 'client'"
        svgIcon="client"></mat-icon>
      <mat-icon
        *ngIf="user && user.type === 'current-user'"
        svgIcon="client"></mat-icon>
    </td>
    <td class="f6 t-df p-df t-jc p-jc">
      {{ user ? user.name : '' }}
    </td>
    <td class="f6">
      {{ user ? user.mail : '' }}
    </td>
    <td class="f2 jc">
      <label>
        <T str="Present"></T>
      </label>
      <mat-slide-toggle
        [checked]="user && user.present"
        disabled>
      </mat-slide-toggle>
    </td>
    <td class="f2 jc">
      <label>
        <T str="Report"></T>
      </label>
      <mat-slide-toggle
        [checked]="user && user.report"
        disabled>
      </mat-slide-toggle>
    </td>
    <td class="f2 jc">
      <label>
        <T str="Signer"></T>
      </label>
      <mat-slide-toggle
        [checked]="user && user.signer"
        disabled>
      </mat-slide-toggle>
    </td>
  </tr>
</ng-template>

<ng-container *ngIf="inspection">
  <header>
    <apex-page-info [title]="title">
      <div
        *ngIf="inspectionService.sw"
        class="df f1 jfe">
        <button
          mat-icon-button
          (click)="inspectionService.setOfflineMode(false)"
          [disabled]="true">
          <mat-icon *ngIf="!inspectionService.savingOfflineData">
            {{ inspectionService.offline ? 'wifi_off' : 'wifi' }}
          </mat-icon>
          <mat-spinner
            *ngIf="inspectionService.savingOfflineData"
            color="warn"
            [diameter]="24">
          </mat-spinner>
        </button>
      </div>
    </apex-page-info>
  </header>
  <main class="mat-fab-component-padding">
    <div
      *ngIf="!ongoing && inspection.id"
      class="ap">
      <apex-file-usage
        self="inspection"
        [selfId]="inspection.id"
        name="pdf"
        [title]="'Reports' | translate"
        [disabled]="true">
      </apex-file-usage>
    </div>
    <div class="df r">
      <div
        *ngIf="inspection.data?.Object"
        class="df ac">
        <mat-icon class="am">{{ inspection.data?.Object | icon }}</mat-icon>
        <span>{{ inspection.data?.Object.name }}</span>
      </div>
      <div
        *ngIf="inspection.Project"
        class="df ac">
        <mat-icon
          svgIcon="project"
          class="am"></mat-icon>
        <span>{{ inspection.Project.name }}</span>
      </div>
      <div
        *ngIf="inspection.Apartment"
        class="df ac">
        <mat-icon
          svgIcon="apartment"
          class="am"></mat-icon>
        <span>{{ inspection.Apartment.name }}</span>
      </div>
    </div>
    <div
      *ngIf="inspection?.data?.Field"
      class="am">
      <p>
        <T
          str="Discipline - {name}"
          [vars]="{ name: inspection?.data?.Field?.name }">
        </T>
      </p>
    </div>
    <div class="am">
      <p>
        <T str="Involved"></T>
        -
        <T str="Signature type"></T>
        :
        {{ inspection?.data?.signatureType | translateType }}
      </p>
      <div>
        <table
          *ngIf="inspection.data.Users && inspection.data.Users.length"
          class="apex phone tablet">
          <thead>
            <tr>
              <th class="f1 jc"></th>
              <th class="f6">
                <T str="Name"></T>
              </th>
              <th class="f6">
                <T str="Email"></T>
              </th>
              <th class="f2 jc">
                <T str="Present"></T>
              </th>
              <th class="f2 jc">
                <T str="Report"></T>
              </th>
              <th class="f2 jc">
                <T str="Signer"></T>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let user of users">
              <ng-container *ngTemplateOutlet="row; context: { $implicit: user }"></ng-container>
            </ng-container>
            <tr
              *ngIf="others && others.length"
              class="others-seperator">
              <td></td>
            </tr>
            <ng-container *ngFor="let user of others">
              <ng-container *ngTemplateOutlet="row; context: { $implicit: user }"></ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <ng-container *ngIf="checklist">
      <apex-inspection-checklist-info
        class="amh amb"
        [checklistData]="checklist"
        [expanded]="true"
        [hidePromt]="true">
      </apex-inspection-checklist-info>
    </ng-container>
    <ng-container *ngIf="checklistGroup">
      <apex-inspection-checklist-info
        class="amh amb"
        [checklistData]="checklistGroup"
        [expanded]="true"
        [hidePromt]="true">
      </apex-inspection-checklist-info>
    </ng-container>
    <apex-checklist-group-checklist
      *ngIf="checklist"
      class="amh"
      [checklist]="checklist">
    </apex-checklist-group-checklist>
    <apex-checklist-group-tree
      *ngIf="checklistGroup"
      class="amh amr"
      [group]="checklistGroup"
      [showItems]="true"
      [hideHeader]="true">
    </apex-checklist-group-tree>
    <div
      *ngIf="cases?.length"
      class="am">
      <p>
        <T str="Cases"></T>
      </p>

      <div class="disable-element">
        <apex-case-list
          [cases]="cases"
          [highlightedCaseIds]="inspection.data.newCaseIds">
        </apex-case-list>
        <apex-case-marking
          *ngIf="floorplans?.length"
          [cases]="cases"
          [floorplans]="floorplans"
          [editable]="false"
          [disabled]="true"
          [highlightedCaseIds]="inspection.data.newCaseIds">
        </apex-case-marking>
      </div>
    </div>
    <div class="ap">
      <p>
        <T str="Notes and attachments"></T>
      </p>
      <div>
        <div [innerHTML]="inspection.data.notes"></div>
      </div>
      <div>
        <apex-file-usage
          #miscInfoFiles
          self="inspection"
          [selfId]="ongoing ? 0 : inspection.id"
          name="misc-info"
          [title]="'Attachments' | translate"
          [disabled]="true">
        </apex-file-usage>
      </div>
    </div>
    <div class="footer-padding"></div>
    <footer class="df jsb">
      <div class="df ac jc">
        <div
          class="df ac"
          *ngIf="ongoing">
          <button
            class="aml"
            mat-flat-button
            color="primary"
            [routerLink]="['/', 'involved']"
            queryParamsHandling="merge">
            <T str="Back"></T>
          </button>
        </div>
      </div>

      <div class="df ac">
        <div
          class="df ac"
          *ngIf="!ongoing">
          <button
            *ngIf="!ongoing && inspection.id"
            class="amr"
            mat-raised-button
            color="warn"
            (click)="deleteInspection(inspection)">
            <T str="Delete inspection"></T>
          </button>

          <button
            class="amr"
            mat-raised-button
            color="primary"
            (click)="newInspection()">
            <T str="New inspection"></T>
          </button>
        </div>

        <div
          *ngIf="inspectionService.savingInspection"
          style="position: absolute">
          <mat-spinner
            [diameter]="24"
            *ngIf="true">
          </mat-spinner>
        </div>
        <button
          class="amr"
          mat-raised-button
          color="primary"
          *ngIf="ongoing"
          [style.visibility]="inspectionService.savingInspection ? 'hidden' : 'visible'"
          (click)="inspectionService.offline ? saveInspection() : completeInspection()"
          [disabled]="!!inspectionService.savingOfflineData || !!inspectionService.savingInspection">
          <T str="Complete"></T>
        </button>
      </div>
    </footer>
  </main>
</ng-container>
