import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Autocomplete } from 'projects/apex/src/app/models/autocomplete';
import { InspectionSignatureType } from 'projects/apex/src/app/models/inspection';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'apex-inspection-add-other-user-dialog',
  templateUrl: './add-other-user-dialog.component.html',
})
export class AddOtherUserDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  user: User = new User();
  signatureType: InspectionSignatureType;

  private sub: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: { signatureType: InspectionSignatureType },
    public dialogRef: MatDialogRef<AddOtherUserDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.dialogRef?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
    this.signatureType = this.data.signatureType;
    this.setupForm();
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      mail: ['', Validators.email],
      present: {
        value: false,
        disabled: false,
      },
      report: {
        value: false,
        disabled: true,
      },
      signer: {
        value: false,
        disabled:
          this.data &&
          (this.signatureType === InspectionSignatureType.BankId ||
            this.signatureType === InspectionSignatureType.None),
      },
    });
    this.sub?.unsubscribe();
    this.sub = this.form.valueChanges.subscribe((values) => {
      const report = this.form.get('report');
      const signer = this.form.get('signer');
      const mail = this.form.get('mail');

      if (!values.mail || values.mail === '' || mail.invalid) {
        this.form.patchValue({ report: false }, { emitEvent: false });
        report.disable({ emitEvent: false });

        if (this.signatureType === InspectionSignatureType.BankId) {
          this.form.patchValue({ signer: false }, { emitEvent: false });
          signer.disable({ emitEvent: false });
        }
      } else {
        report.enable({ emitEvent: false });

        if (this.signatureType !== InspectionSignatureType.None) {
          signer.enable({ emitEvent: false });
        }
      }
    });
  }

  addApexUser(user: Autocomplete): void {
    if (user && user.id) {
      this.userService
        .get(user.id)
        .pipe(take(1))
        .subscribe((u: User) => {
          this.form.patchValue(u);
        });
    } else {
      this.form.patchValue({
        name: '',
        mail: '',
      });
    }
  }

  confirm(): void {
    this.dialogRef.close(this.form.value);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
