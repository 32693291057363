import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { PageHeaderModule } from 'projects/apex/src/app/components/page-header/page-header.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { UploadModule } from 'projects/apex/src/app/components/upload/upload.module';
import { InspectionModule } from 'projects/apex/src/app/features/inspection/inspection.module';
import { HttpErrorInterceptor } from 'projects/apex/src/app/interceptors/http-error.interceptor';
import { SnackModule } from 'projects/apex/src/app/modules/snack.module';
import { FilterService } from 'projects/apex/src/app/services/filter/filter.service';
import { SwService } from 'projects/apex/src/app/services/sw/sw.service';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { locale } from 'projects/apex/src/app/utils/functions';
import { localeInitializer } from 'projects/apex/src/app/utils/locale-init';
import { environment } from 'projects/apex/src/environments/environment';
import { RequestStatusInterceptor } from '../../../apex/src/app/interceptors/status/request-status.interceptor';
import StatusServiceModule from '../../../apex/src/app/interceptors/status/status.service.module';
import { RaygunErrorHandler } from '../../../apex/src/app/utils/error-handler';
import { LocaleProvider } from '../../../apex/src/app/utils/locale-provider';
import { inspectionAuthDestinationProvider } from '../../../apex/src/app/utils/types/app-url.token';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InspectionInterceptor } from './inspection.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    ServiceWorkerModule.register('/service-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    InspectionModule,
    SnackModule,
    StatusServiceModule,
    AutocompleteModule,
    UploadModule,
    PageHeaderModule,

    TranslateModule,

    MatMomentDateModule,

    HammerModule,

    TranslateModule,
  ],
  providers: [
    SwService,
    LocaleProvider,
    {
      provide: APP_INITIALIZER,
      useValue: (): Promise<void> => Promise.resolve(localeInitializer(locale())),
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: locale(),
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    },
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: InspectionInterceptor,
        multi: true,
      },
    ],
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true,
      },
    ],
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestStatusInterceptor,
        multi: true,
      },
    ],
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },

    UserService,
    FilterService,
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: `${environment.appUrl}/tinymce/tinymce.min.js`,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    inspectionAuthDestinationProvider(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
