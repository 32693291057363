import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { InspectionService } from 'projects/apex/src/app/features/inspection/inspection.service';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export const InspectionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> | UrlTree => {
  const router = inject(Router);
  const service = inject(InspectionService);

  if (
    (route.queryParams.Project || route.queryParams.Object) &&
    route.queryParams.Category &&
    route.queryParams.InspectionManagerId
  ) {
    if (route.queryParams.InspectionId) {
      return from(service.dexie.Inspections.get(Number(route.queryParams.InspectionId))).pipe(
        map((inspection) => (inspection ? true : router.parseUrl('/info'))),
      );
    }
  }

  return router.parseUrl('/info');
};
