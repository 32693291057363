import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUsageComponent } from 'projects/apex/src/app/components/file-usage/file-usage.component';
import { FileUsageChangeEventResponse } from 'projects/apex/src/app/components/file-usage/file-usage.types';
import { File } from 'projects/apex/src/app/models/file';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { take } from 'rxjs/operators';
import { t } from '../../../components/translate/translate.function';
import { Inspection } from '../../../models/inspection';
import { InspectionService } from '../inspection.service';

@Component({
  selector: 'apex-inspection-misc-info-page',
  templateUrl: './misc-info.component.html',
})
export class MiscInfoPageComponent implements OnInit, AfterViewInit {
  @ViewChild('miscInfoFiles') miscInfoFiles: FileUsageComponent;

  get title(): string {
    return t('Notes and Attachments');
  }

  inspection: Inspection;
  notes: string;

  constructor(
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.route?.snapshot?.data?.inspection) {
      this.inspection = this.route.snapshot.data.inspection;
      this.notes = this.inspection?.data?.notes ? this.inspection.data.notes : '';
    } else {
      void this.router.navigate(['/', 'info']);
    }
  }

  ngAfterViewInit(): void {
    if (this.inspection?.data?.Files?.length) {
      setTimeout(() => {
        const fileUsages: FileUsage[] = this.getDummyFileUsageFromFiles(this.inspection.data.Files);

        this.miscInfoFiles.fileUsages = fileUsages;
      });
    }
  }

  getDummyFileUsageFromFiles(files: File[]): FileUsage[] {
    return files.map((f, i) => {
      const fileUsage = new FileUsage();

      fileUsage.id = i + 1;
      fileUsage.FileId = f.id;
      fileUsage.File = f;
      fileUsage.self = 'inspection';
      fileUsage.name = 'misc-info';
      fileUsage.fileName = f.name;
      fileUsage.isNew = true;

      return fileUsage;
    });
  }

  save(event?: FileUsageChangeEventResponse): void {
    this.inspection.data.notes = this.notes;

    if (event && Array.isArray(this.miscInfoFiles.fileUsages)) {
      this.inspection.data.Files = this.miscInfoFiles.fileUsages.map((f) => f.File);
    }

    this.inspectionService.saveLocalInspection(this.inspection).pipe(take(1)).subscribe();
  }

  back(): void {
    void this.router.navigate(['/case'], { queryParamsHandling: 'preserve' });
  }

  next(): void {
    void this.router.navigate(['/involved'], { queryParamsHandling: 'preserve' });
  }
}
