import { Checklist, ChecklistItem, ChecklistSection } from '../features/checklist/checklist.model';
import { Obj } from '../features/object/object.model';
import { Apartment } from './apartment';
import { Case } from './case';
import { CaseCategory } from './case-category';
import { ChecklistGroup } from './checklist-group';
import { Field } from './field';
import { File } from './file';
import { FileUsage } from './file-usage';
import { Model } from './model';
import { ObjectField } from './object-field';
import { Project } from './project';
import { User } from './user';

export enum InspectionInvolvedUserType {
  CurrentUser = 'current-user',
  CaseManager = 'casemanager',
  Contractor = 'contractor',
  Client = 'client',
}

export interface InspectionChecklistData {
  groups: ChecklistGroup[];
  checklists: Checklist[];
  sections: ChecklistSection[];
  items: ChecklistItem[];
}

export class InspectionInvolvedUser {
  id: number;
  name: string;
  mail: string;
  type: InspectionInvolvedUserType;
  present: boolean;
  report: boolean;
  signer: boolean;
  signatureId?: number;
}

export enum InspectionSignatureType {
  None = 'none',
  Handwritten = 'handwritten',
  BankId = 'bankid',
}

export interface InspectionData {
  floorplans?: FileUsage[];

  inspectionDate: Date;

  signatureType?: InspectionSignatureType;
  Users?: InspectionInvolvedUser[];

  notes?: string;
  Files?: File[];

  caseIds?: number[];
  Cases?: Case[];
  newCaseIds?: number[];
  showCompleted?: boolean;

  ChecklistTemplateId?: number;
  ChecklistGroupTemplateId?: number;

  ChecklistId?: number;
  ChecklistGroupId?: number;

  checklistData?: InspectionChecklistData;

  CaseManagerId: number;
  CaseManager?: User;

  ClientId: number;
  Client?: User;

  Contractors?: User[];

  CategoryId: number;
  Category?: CaseCategory;

  FieldId: number;
  Field?: Field;

  ObjectId: number;
  Object?: Obj;

  ObjectFieldId: number;
  ObjectField?: ObjectField;

  locale?: string;
}

export class InspectionSignature {
  id: number;
  signature: string;
}

const inspectionRequiredFields = ['data', 'signatures', 'ProjectId', 'Project', 'ApartmentId', 'Apartment'];

const inspectionDataRequiredFields = ['signatureType', 'Users', 'Cases', 'CaseManager', 'Category'];

export class Inspection extends Model<Inspection> {
  signatures: InspectionSignature[];
  data: InspectionData;

  ApartmentId: number;
  Apartment?: Apartment;

  ProjectId: number;
  Project?: Project;

  ObjectId: number;
  Object?: Obj;

  UserId: number;
  User?: User;

  Category?: CaseCategory;
  FileUsage?: FileUsage;

  // Indicates if it's too old to show, only PDF.
  old?: boolean;

  constructor(inspection?: Partial<Inspection>) {
    super(inspection);

    if (!inspection?.data) {
      this.data = {
        inspectionDate: undefined,
        CaseManagerId: undefined,
        ClientId: undefined,
        CategoryId: undefined,
        FieldId: undefined,
        ObjectId: undefined,
        ObjectFieldId: undefined,
        ChecklistTemplateId: undefined,
        ChecklistGroupTemplateId: undefined,
      };
    }
  }

  hasRequiredData(): boolean {
    if (!this.data.caseIds.length || !this.data.Users.length) {
      return false;
    }

    const missingField = inspectionRequiredFields.some((f) => !this[f]);
    const missingDataField = inspectionDataRequiredFields.some((f) => !this.data[f]);

    return !missingField && !missingDataField;
  }

  offline?: boolean;
}
