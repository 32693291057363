import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Checklist } from 'projects/apex/src/app/features/checklist/checklist.model';
import { ChecklistGroup } from 'projects/apex/src/app/models/checklist-group';
import { Observable, Subscription, forkJoin, from, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { t } from '../../../components/translate/translate.function';
import { Inspection } from '../../../models/inspection';
import { snackErr } from '../../../modules/snack.module';
import { ChecklistGroupService } from '../../checklist-group/checklist-group.service';
import { ChecklistService } from '../../checklist/checklist.service';
import { InspectionService } from '../inspection.service';

@Component({
  selector: 'apex-inspection-checklist-page',
  templateUrl: './checklist.component.html',
})
export class ChecklistPageComponent implements OnInit, OnDestroy {
  readonly InspectionIdString = 'InspectionId';

  get title(): string {
    return t('Checklist');
  }

  public inspection: Inspection;

  checklist: Checklist;
  checklistGroup: ChecklistGroup;

  loading = false;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private checklistService: ChecklistService,
    private checklistGroupService: ChecklistGroupService,
    public inspectionService: InspectionService,
  ) {}

  ngOnInit(): void {
    this.inspection = this.route?.snapshot?.data?.inspection;

    this.loading = true;

    this.subscription.add(
      forkJoin({
        checklist: this.checklist$,
        checklistGroup: this.checklistGroup$,
      })
        .pipe(
          mergeMap((res) => {
            this.checklist = res.checklist;
            this.checklistGroup = res.checklistGroup;

            this.inspection.data.ChecklistId = res.checklist?.id ?? null;
            this.inspection.data.ChecklistGroupId = res.checklistGroup?.id ?? null;

            return from(this.inspectionService.dexie.Inspections.get(this.inspection.id)).pipe(
              mergeMap((inspection) => {
                inspection.data.ChecklistId = this.checklist?.id ?? null;
                inspection.data.ChecklistGroupId = this.checklistGroup?.id ?? null;

                this.inspection = inspection;

                return from(this.inspectionService.dexie.Inspections.put(this.inspection));
              }),
            );
          }),
        )
        .subscribe({
          next: (_) => {
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;

            snackErr(t('Could not load checklist'), err);
          },
        }),
    );

    if (this.inspectionService.offline) {
      this.setOfflineClass();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setOfflineClass(): void {
    window.document.body.classList.add('offline');
  }

  next(): void {
    void this.router.navigate(['/', 'case'], { queryParamsHandling: 'preserve' });
  }

  get checklist$(): Observable<Checklist> {
    const id = Number(this.inspection.data.ChecklistId);

    if (id) {
      return this.checklistService.get(id).pipe(map((e) => e.Entity));
    }

    return this.inspection.data.ChecklistTemplateId ? this.checklistFromTemplate$ : of(null);
  }

  get checklistGroup$(): Observable<ChecklistGroup> {
    const id = Number(this.inspection.data.ChecklistGroupId);

    if (id) {
      return this.checklistGroupService.get(id).pipe(map((e) => e.Entity));
    }

    return this.inspection.data.ChecklistGroupTemplateId ? this.checklistGroupFromTemplate$ : of(null);
  }

  get checklistFromTemplate$(): Observable<Checklist> {
    const tid = Number(this.inspection.data.ChecklistTemplateId);
    const inspectionModel = this.inspectionModel;

    if (tid) {
      const params: Record<string, string> = {};

      if (this.inspection?.data?.CaseManagerId) {
        params.caseManagerId = String(this.inspection.data.CaseManagerId);
      }

      if (this.inspection?.data?.ObjectFieldId) {
        params.objectFieldId = String(this.inspection.data.ObjectFieldId);
      }

      if (this.inspection?.data?.FieldId) {
        params.fieldId = String(this.inspection.data.FieldId);
      }

      return this.checklistService
        .createChecklistFromTemplate(tid, inspectionModel.model, inspectionModel.modelId, {
          params,
        })
        .pipe(map((e) => e.Entity));
    }

    return of(null);
  }

  get checklistGroupFromTemplate$(): Observable<ChecklistGroup> {
    const tid = Number(this.inspection.data.ChecklistGroupTemplateId);
    const inspectionModel = this.inspectionModel;

    if (tid) {
      const params: Record<string, string> = {};

      if (this.inspection?.data?.CaseManagerId) {
        params.caseManagerId = String(this.inspection.data.CaseManagerId);
      }

      if (this.inspection?.data?.ObjectFieldId) {
        params.objectFieldId = String(this.inspection.data.ObjectFieldId);
      }

      if (this.inspection?.data?.FieldId) {
        params.fieldId = String(this.inspection?.data?.FieldId);
      }

      return this.checklistGroupService
        .createGroupFromTemplateOnModel(tid, inspectionModel.model, inspectionModel.modelId, {
          params,
        })
        .pipe(map((e) => e.Entity));
    }

    return of(null);
  }

  get inspectionModel(): { model: string; modelId: number } {
    let model: string;
    let modelId: number;

    if (this.inspection.ProjectId) {
      model = 'project';
      modelId = this.inspection.ProjectId;
    }

    if (this.inspection.ApartmentId) {
      model = 'apartment';
      modelId = this.inspection.ApartmentId;
    }

    if (this.inspection.ObjectId || this.inspection.data.ObjectId) {
      model = 'object';
      modelId = this.inspection.ObjectId || this.inspection.data.ObjectId;
    }

    return {
      model,
      modelId,
    };
  }
}
