import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { t } from '../../../components/translate/translate.function';
import { CaseCategory } from '../../../models/case-category';
import { getCaseCategory$ } from '../../../models/case-category.data';
import { ChecklistGroup } from '../../../models/checklist-group';
import { ObjectField } from '../../../models/object-field';
import { snack, snackErr } from '../../../modules/snack.module';
import { ChecklistGroupService } from '../../checklist-group/checklist-group.service';
import { Checklist } from '../../checklist/checklist.model';
import { ChecklistService } from '../../checklist/checklist.service';

@Component({
  selector: 'apex-inspection-checklist-info',
  templateUrl: './checklist-info.component.html',
})
export class ChecklistInfoComponent implements OnChanges, OnDestroy {
  @Input() checklistData: Checklist | ChecklistGroup;
  @Input() expanded = false;
  @Input() hidePromt = false;

  caseCategory$: Observable<CaseCategory>;

  isChecklist = false;
  isChecklistGroup = false;

  private subscription = new Subscription();

  private projectId: number = null;
  private apartmentId: number = null;
  private objectId: number = null;
  private categoryId: number = null;

  constructor(
    private checklistService: ChecklistService,
    private checklistGroupService: ChecklistGroupService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.checklistData && this.checklistData) {
      if (this.checklistData.ObjectField) {
        this.checklistData.ObjectField = new ObjectField(this.checklistData.ObjectField);
      }

      this.updateCategory();

      this.subscription.add(
        this.route.queryParams
          .pipe(
            tap(() => {
              this.isChecklist = 'CategoryId' in this.checklistData;
              this.isChecklistGroup = !('CategoryId' in this.checklistData);
            }),
            filter((params) => !!params && Object.keys(params).length > 0),
          )
          .subscribe({
            next: (params) => {
              this.isChecklist = !!Number(params.ChecklistTemplate);
              this.isChecklistGroup = !!Number(params.ChecklistGroupTemplate);

              this.projectId = Number(params.Project);
              this.apartmentId = Number(params.Apartment);
              this.objectId = Number(params.Object);

              const projectDiff = this.isDiff(this.projectId, this.checklistData.ProjectId);
              const apartmentDiff = this.isDiff(this.apartmentId, this.checklistData.ApartmentId);
              const objectDiff = this.isDiff(this.objectId, this.checklistData.ObjectId);

              let categoryDiff = false;

              if ('CategoryId' in this.checklistData) {
                this.categoryId = Number(params.Category);

                categoryDiff = this.isDiff(this.categoryId, this.checklistData.CategoryId);
              }

              const diffs = [
                projectDiff ? t('Project') : null,
                apartmentDiff ? t('Unit') : null,
                objectDiff ? t('Object') : null,
                categoryDiff ? t('Category') : null,
              ].filter((d) => !!d);

              const message = t(
                `The following properties does not have the same value as spesified in the inspection: {diffs}`,
                {
                  diffs: diffs.join(', '),
                },
              );

              if (
                (projectDiff || apartmentDiff || objectDiff || (this.isChecklist && categoryDiff)) &&
                !this.hidePromt
              ) {
                this.expanded = true;
                this.promtChangeDialog(message);
              }
            },
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private promtChangeDialog(text: string): void {
    this.subscription.add(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text,
            description: t('Do you want to change the values to match the inspection?'),
          },
        })
        .afterClosed()
        .pipe(filter((res) => !!res))
        .subscribe({
          next: (res) => {
            if (res && this.isChecklist) {
              this.saveChecklist();
            } else if (res && this.isChecklistGroup) {
              this.saveChecklistGroup();
            }
          },
        }),
    );
  }

  private saveChecklist(): void {
    const dataToSave = {
      ...this.checklistData,
      ProjectId: this.projectId,
      ApartmentId: this.apartmentId,
      ObjectId: this.objectId,
      CategoryId: this.categoryId,
    } as Checklist;

    this.subscription.add(
      this.checklistService.save(dataToSave).subscribe({
        next: (data) => {
          this.checklistData = data.Entity;

          if (this.checklistData?.ObjectField) {
            this.checklistData.ObjectField = new ObjectField(this.checklistData.ObjectField);
          }

          this.updateCategory();

          snack(t('Saved'));
        },
        error: (err) => snackErr(t('Could not save'), err),
      }),
    );
  }

  private saveChecklistGroup(): void {
    const dataToSave = {
      ...this.checklistData,
      ProjectId: this.projectId,
      ApartmentId: this.apartmentId,
      ObjectId: this.objectId,
    } as ChecklistGroup;

    this.subscription.add(
      this.checklistGroupService.save(dataToSave).subscribe({
        next: (data) => {
          this.checklistData = data.Entity;

          if (this.checklistData?.ObjectField) {
            this.checklistData.ObjectField = new ObjectField(this.checklistData.ObjectField);
          }

          snack(t('Saved'));
        },
        error: (err) => snackErr(t('Could not save'), err),
      }),
    );
  }

  private isDiff(paramValue: number, checklistValue: number): boolean {
    return (!!paramValue || !!checklistValue) && paramValue !== checklistValue;
  }

  private updateCategory(): void {
    const categoryId = (this.checklistData as Checklist).CategoryId;

    this.caseCategory$ = of(null).pipe(
      filter(() => !!categoryId),
      mergeMap(() => getCaseCategory$(categoryId)),
    );
  }
}
