import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { PromiseExtended } from 'dexie';
import { CaseCategoryService } from 'projects/apex/src/app/features/case/case-category.service';
import { InspectionService } from 'projects/apex/src/app/features/inspection/inspection.service';
import { Inspection } from 'projects/apex/src/app/models/inspection';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Field } from '../../models/field';
import { FieldService } from '../field/field.service';

export const InspectionGetResolver: ResolveFn<Inspection> = (route: ActivatedRouteSnapshot) => {
  const inspectionService = inject(InspectionService);
  const caseCategoryService = inject(CaseCategoryService);
  const fieldService = inject(FieldService);

  return forkJoin([
    inspectionService.get(Number(route.paramMap.get('id'))).pipe(map((res) => res.Entity)),
    caseCategoryService.query(),
  ]).pipe(
    mergeMap(([inspection, caseCategories]) => {
      inspection.data.Category = caseCategories.find((cc) => cc.id === inspection.data.CategoryId);

      if (inspection.data.FieldId) {
        return fieldService.get(inspection.data.FieldId).pipe(
          map((field: Field) => {
            inspection.data.Field = field;

            return inspection;
          }),
        );
      } else {
        return of(inspection);
      }
    }),
  );
};

export const InspectionGetSavedByQueryParamResolver: ResolveFn<PromiseExtended<Inspection>> = (
  route: ActivatedRouteSnapshot,
) => {
  const inspectionService = inject(InspectionService);

  return inspectionService.dexie.Inspections.get(Number(route.queryParams.InspectionId));
};
