import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { FileUsageModule } from 'projects/apex/src/app/components/file-usage/file-usage.module';
import { CaseModule } from 'projects/apex/src/app/features/case/case.module';
import { ChecklistGroupModule } from 'projects/apex/src/app/features/checklist-group/checklist-group.module';
import { CasePageComponent } from 'projects/apex/src/app/features/inspection/case/case.component';
import { ChecklistPageComponent } from 'projects/apex/src/app/features/inspection/checklist/checklist.component';
import { InfoPageComponent } from 'projects/apex/src/app/features/inspection/info/info.component';
import { InspectionService } from 'projects/apex/src/app/features/inspection/inspection.service';
import { AddOtherUserDialogComponent } from 'projects/apex/src/app/features/inspection/involved/add-other-user-dialog.component';
import { InvolvedPageComponent } from 'projects/apex/src/app/features/inspection/involved/involved.component';
import { MiscInfoPageComponent } from 'projects/apex/src/app/features/inspection/misc-info/misc-info.component';
import { InspectionViewPageComponent } from 'projects/apex/src/app/features/inspection/view/view.component';
import { ProjectService } from 'projects/apex/src/app/features/project/project.service';
import { MarkingService } from 'projects/apex/src/app/services/marking/marking.service';
import { AutocompleteModule } from '../../components/autocomplete/autocomplete.module';
import { BoxModule } from '../../components/box/box.module';
import { HandwrittenSigningModule } from '../../components/handwritten-signing/handwritten-signing.module';
import { PageInfoModule } from '../../components/page-info/page-info.module';
import { ProjectObjectFieldSelectorModule } from '../../components/project-object-field/project-object-field-selector.module';
import { TranslateModule } from '../../components/translate/translate.module';
import { EditorInitPipeModule } from '../../pipes/editor-init/editor-init.pipe.module';
import { IconPipeModule } from '../../pipes/icon/icon-pipe.module';
import { ApartmentService } from '../apartment/apartment.service';
import { ChecklistGroupService } from '../checklist-group/checklist-group.service';
import {
  ChecklistTemplateItemService,
  ChecklistTemplateSectionService,
  ChecklistTemplateService,
} from '../checklist-template/checklist-template.service';
import { ChecklistService } from '../checklist/checklist.service';
import { ClientService } from '../client/client.service';
import { FieldService } from '../field/field.service';
import { ObjectSelectorModule } from '../object/components/selector/object-selector.module';
import { ChecklistInfoComponent } from './checklist/checklist-info.component';
import { InspectionNestService } from './inspection-nest.service';
import { InspectionRoutingModule } from './inspection-routing.module';

@NgModule({
  declarations: [
    InfoPageComponent,
    ChecklistPageComponent,
    ChecklistInfoComponent,
    CasePageComponent,
    MiscInfoPageComponent,
    InvolvedPageComponent,
    InspectionViewPageComponent,
    AddOtherUserDialogComponent,
  ],
  providers: [
    InspectionService,
    InspectionNestService,
    MarkingService,
    ProjectService,
    ApartmentService,
    ClientService,
    FieldService,
    ChecklistService,
    ChecklistGroupService,
    ChecklistTemplateService,
    ChecklistTemplateSectionService,
    ChecklistTemplateItemService,
  ],
  imports: [
    InspectionRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    MatExpansionModule,
    CaseModule,
    ObjectSelectorModule,
    HttpClientModule,
    FileUsageModule,
    TranslateModule,
    PageInfoModule,
    AutocompleteModule,
    IconPipeModule,
    HandwrittenSigningModule,
    ChecklistGroupModule,
    ProjectObjectFieldSelectorModule,
    EditorComponent,
    EditorInitPipeModule,
    BoxModule,
    TranslateModule,
  ],
})
export class InspectionModule {}
