import { Component } from '@angular/core';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable } from 'rxjs';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'apex-page-header',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent {
  profile$: Observable<Profile | null>;

  constructor(private userService: UserService) {
    this.profile$ = this.userService.profile$;
  }

  url = environment.apexUrl;
}
