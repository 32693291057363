<header>
  <apex-page-info [title]="title">
    <div
      *ngIf="inspectionService.sw"
      class="df f1 jfe">
      <button
        *ngIf="inspectionService.sw"
        mat-icon-button
        class="offline-mode-button"
        (click)="inspectionService.setOfflineMode(!inspectionService.offline, data)"
        [disabled]="
          inspectionService.savingOfflineData || (!data.Project && !data.Object && !inspectionService.offline)
        ">
        <mat-icon *ngIf="!inspectionService.savingOfflineData">
          {{ inspectionService.offline ? 'wifi_off' : 'wifi' }}
        </mat-icon>
        <mat-spinner
          *ngIf="inspectionService.savingOfflineData"
          color="warn"
          [diameter]="24">
        </mat-spinner>
      </button>
    </div>
  </apex-page-info>
</header>
<main class="df c">
  <section>
    <form
      #infoForm="ngForm"
      class="mat-elevation-z2 ap">
      <apex-autocomplete
        name="Category"
        [(ngModel)]="data.Category"
        type="CaseCategory"
        [label]="'Category' | translate"
        required>
      </apex-autocomplete>
      <apex-object-selector
        name="object"
        [(ngModel)]="data.Object"
        [multiple]="false"
        [disabled]="!!data.Project"
        [label]="'Object' | translate"
        required>
      </apex-object-selector>
      <div class="df ac">
        <apex-autocomplete
          name="Project"
          [(ngModel)]="data.Project"
          (modelChange)="updateProject($event?.id)"
          class="f1"
          type="Project"
          [label]="'Project' | translate"
          [disabled]="!!data.Object"
          required>
        </apex-autocomplete>
      </div>
      <apex-autocomplete
        name="Apartment"
        [(ngModel)]="data.Apartment"
        type="Apartment"
        [label]="'Unit' | translate"
        [params]="{
          ProjectId: data.Project,
        }"
        [disabled]="!!data.Object">
      </apex-autocomplete>
      <apex-autocomplete
        name="Client"
        [(ngModel)]="data.Client"
        type="Client"
        [label]="'Customer' | translate"
        [params]="{
          ProjectId: data.Project,
          ApartmentId: data.Apartment,
        }"
        [disabled]="!!data.Object"></apex-autocomplete>
      <apex-autocomplete
        [(ngModel)]="data.InspectionManagerId"
        name="InspectionManagerId"
        type="User"
        [label]="'Inspection Manager' | translate"
        [params]="{
          subscribed: true,
        }"
        required>
      </apex-autocomplete>

      <div class="apb">
        <apex-project-object-field-selector
          [data]="data"
          (modelChange)="updateObjectFieldOrField()">
        </apex-project-object-field-selector>
      </div>

      <div class="df gap-vertical-small w">
        <apex-autocomplete
          class="f1 d-amrs"
          name="Checklist"
          [(ngModel)]="data.ChecklistTemplate"
          type="ChecklistTemplate"
          [disabled]="data.ChecklistGroupTemplate || inspectionService.offline ? true : false"
          [label]="'Checklist' | translate">
        </apex-autocomplete>
        <apex-autocomplete
          class="f1 d-amls"
          name="ChecklistGroup"
          [(ngModel)]="data.ChecklistGroupTemplate"
          type="ChecklistGroupTemplate"
          [disabled]="data.ChecklistTemplate || inspectionService.offline ? true : false"
          [label]="'Checklist group' | translate">
        </apex-autocomplete>
      </div>

      <apex-language-picker
        [(ngModel)]="data.locale"
        name="locale">
      </apex-language-picker>
    </form>
  </section>
  <section
    *ngIf="savedInspections?.length"
    class="f1 saved-inspections">
    <div class="mat-elevation-z2 am ap AK-50-bg">
      <div
        *ngFor="let inspection of savedInspections; let i = index"
        class="df r w f1 ac">
        <div class="df ac r w f1 p-w-100">
          <div class="mat-body f5">
            <T
              str="Not completed"
              *ngIf="!inspection?.data?.inspectionDate"></T>
            <ng-container *ngIf="inspection?.data?.inspectionDate">
              {{ inspection.data.inspectionDate | date: 'short' }}
            </ng-container>
            -
            <!-- @note this should be handled by caseCategories -->
            {{ inspection.data?.Category?.name | translate }}
          </div>
          <div class="mat-body f4">
            {{ inspection.User?.name }}
          </div>
          <div
            *ngIf="inspection.data?.Object"
            class="df ac f4">
            <mat-icon class="aprs">{{ inspection.data.Object | icon }}</mat-icon>
            <div class="mat-body">{{ inspection.data.Object?.name }}</div>
          </div>
          <div
            *ngIf="inspection.Project"
            class="df ac f4">
            <mat-icon
              svgIcon="project"
              class="aprs"></mat-icon>
            <div class="mat-body">{{ inspection.Project?.name }}</div>
          </div>
          <div class="df ac f4">
            <ng-container *ngIf="inspection.Apartment">
              <mat-icon
                svgIcon="apartment"
                class="aprs"></mat-icon>
              <div class="mat-body">{{ inspection.Apartment?.name }}</div>
            </ng-container>
          </div>
        </div>
        <div class="df ac jfe">
          <div class="df ac">
            <div class="apr">
              <button
                mat-icon-button
                (click)="deleteSavedInspection(inspection)"
                color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button
              *ngIf="!inspection.data?.inspectionDate"
              mat-raised-button
              (click)="setInspection(inspection)"
              color="primary"
              [disabled]="
                (inspectionService.offline && inspection.ProjectId !== data.Project) ||
                (inspection.offline && !inspectionService.offline)
              ">
              <T str="Continue"></T>
            </button>
          </div>
          <div>
            <button
              *ngIf="inspection.data?.inspectionDate && inspectionService.savingInspection !== inspection.id"
              mat-raised-button
              [disabled]="inspectionService.offline || !!inspectionService.savingInspection"
              (click)="completeInspection(inspection)"
              color="primary">
              <T str="Complete"></T>
            </button>
          </div>
          <mat-spinner
            *ngIf="inspectionService.savingInspection === inspection.id"
            [diameter]="24">
          </mat-spinner>
        </div>
        <mat-divider
          *ngIf="i !== savedInspections.length - 1"
          class="w-100"></mat-divider>
      </div>
    </div>
  </section>
  <footer class="df r jfe ac">
    <button
      class="amr"
      mat-flat-button
      color="primary"
      (click)="next()"
      [disabled]="infoForm.form?.invalid || navigating">
      <T str="Next"></T>
    </button>
  </footer>
</main>
